import axios from 'axios';

/**
 * Deep merges two objets.
 * @param  {Object} object destination object
 * @param  {Object} source source obejct
 *
 * @returns {Object} new object
 */
export const merge = (object, source) => {
  if (object === source) return object;

  const newValue = {
    ...object,
    ...source,
  };

  Object.entries(source).forEach(([key, value]) => {
    if (object[key] && typeof object[key] === 'object') {
      newValue[key] = merge(object[key], value);
    } else {
      newValue[key] = value;
    }
  });

  return newValue;
};

class Api {
  #config;

  #instance;

  constructor(config) {
    this.#config = config;
    this.#instance = axios.create(config);
  }

  update(config) {
    this.#instance = axios.create(merge(this.#config, config));
  }

  appendErrorInterceptor = (interceptor) => {
    this.#instance.interceptors.response.use((r) => r, interceptor);
  };

  appendInterceptor = (addInterceptors) => {
    addInterceptors(this.#instance.interceptors);
  };

  makeRequest(method, url, options = {}) {
    const headers = {
      ...this.#instance.defaults.headers,
      ...options.headers,
    };

    return this.#instance({
      ...options,
      method,
      url,
      headers,
    })
      .then((resp) => resp?.data)
      .catch(({ response }) => Promise.reject(response));
  }

  setAuthToken(auth_token) {
    if (auth_token) {
      this.#instance.defaults.headers.common.Authorization = `Bearer ${auth_token}`;
    } else delete this.#instance.defaults.headers.common.Authorization;
  }

  get = (url, config) => this.makeRequest('get', url, config);

  post = (url, config) => this.makeRequest('post', url, config);

  put = (url, config) => this.makeRequest('put', url, config);

  patch = (url, config) => this.makeRequest('patch', url, config);

  del = (url, config) => this.makeRequest('delete', url, config);

  request = (config) => this.#instance.request(config);
}

export default Api;
