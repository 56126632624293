import React from 'react';
import Footer from './Footer';

const AppLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-100 flex flex-col">
    <main className="flex-1">{children}</main>
    <Footer />
  </div>
);

export default AppLayout;
