import React from 'react';

const Input = React.forwardRef(
  (
    {
      className,
      label,
      error,
      id,
      name = id,
      placeholder,
      type,
      textarea,
      disabled,
      ...props
    },
    ref
  ) => {
    const Component = textarea ? 'textarea' : 'input';
    return (
      <div className={className}>
        {label && (
          <label htmlFor={name} className="sr-only">
            {label}
          </label>
        )}
        <div className="relative rounded-md shadow-sm">
          <Component
            id={name}
            name={name}
            ref={ref}
            type={type}
            disabled={disabled}
            className="form-input block w-full py-3 px-4 placeholder-gray-500 transition ease-in-out duration-150 disabled:opacity-50 disabled:opacity-50"
            placeholder={placeholder}
            {...props}
          />
        </div>
        <p className="text-negative text-sm h-5">{error}</p>
      </div>
    );
  }
);

Input.displayName = 'Input';

export default Input;
