import Api from '~/utils/api';

class FreePdf extends Api {
  contact = (data) => this.post('/contact', { data });
}

const freePdf = new FreePdf({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default freePdf;
