import React from 'react';
import { Link } from 'react-router-dom';
import Button from '~/components/shared/Button';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render(): React.ReactNode {
    const { hasError } = this.state;

    if (process.env.NODE_ENV === 'development') {
      return this.props.children;
    }

    if (hasError) {
      return (
        <div className="w-screen h-screen flex flex-col items-center justify-center">
          <h1 className="mb-2 text-2xl md:text-4xl lg:text-6xl">
            Oops! There’s an error
          </h1>
          <Link to="/">
            <Button>Go to home</Button>
          </Link>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
