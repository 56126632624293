import React from 'react';
import { toast as Toast } from 'react-toastify';
import { Toasts as toast } from '@levinologylabs/pdf_editor';
import { hasValidToken } from '~/utils/helpers';

// layouts
import AppLayout from '~/components/layouts/AppLayout';
import EditorLayout from '~/components/layouts/EditorLayout';

// pages
const HomePage = React.lazy(() => import('~/pages/home-page'));
const EditorPage = React.lazy(() => import('~/pages/editor-page'));
const DownloadPage = React.lazy(() => import('~/pages/download-page'));
const CompanyPage = React.lazy(() => import('~/pages/company-page'));
const TermsOfServicePage = React.lazy(() =>
  import('~/pages/terms-of-service-page')
);
const PrivacyPolicyPage = React.lazy(() =>
  import('~/pages/privacy-policy-page')
);
const NotFoundPage = React.lazy(() => import('~/pages/404-page'));

const routes = [
  {
    path: '/',
    exact: true,
    component: HomePage,
    layout: AppLayout,
  },
  {
    path: '/editor',
    exact: true,
    component: EditorPage,
    layout: EditorLayout,
  },
  {
    path: '/download',
    exact: true,
    component: DownloadPage,
    layout: AppLayout,
  },
  {
    path: '/company',
    exact: true,
    component: CompanyPage,
    layout: AppLayout,
  },
  {
    path: '/terms-of-service',
    exact: true,
    component: TermsOfServicePage,
    layout: AppLayout,
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicyPage,
    layout: AppLayout,
  },
  {
    path: '/',
    exact: false,
    component: NotFoundPage,
    layout: AppLayout,
  },
];

let toastId;

export default {
  routes,
  scrollBehavior: true,
  beforeEnter(to) {
    let redirect = '';

    if (to.path === '/editor' && !hasValidToken()) {
      if (!Toast.isActive(toastId)) {
        toastId = toast.error(
          {
            description: 'Your token has expired',
          },
          {
            toastId: 'token-expired',
          }
        );
      }

      redirect = '/';
    }

    if (to.path === '/download' && !to.location.state?.downloadUrl) {
      redirect = '/';
    }

    return redirect;
  },
};
