import React from 'react';
import cx from 'classnames';

const primary =
  'inline-flex items-center px-4 py-2 border border-primary text-sm leading-5 font-medium rounded-md text-primary bg-white focus:outline-none focus:bg-primary-light transition ease-in-out duration-150';

const secondary =
  'bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-1 px-4 rounded inline-flex items-center';

const secondaryDisabled =
  'text-gray-500 opacity-50 cursor-not-allowed hover:bg-gray-300';

const Button = ({
  children,
  className,
  variant = 'primary',
  disabled,
  ...props
}) => {
  const isPrimary = variant === 'primary';
  const classes = isPrimary ? primary : secondary;
  const disabledClasses = secondaryDisabled;

  return (
    <button
      type="button"
      {...props}
      disabled={disabled}
      className={cx([classes, className, { [disabledClasses]: !!disabled }])}
    >
      {children}
    </button>
  );
};

export default Button;
