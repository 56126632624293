import React from 'react';
import { NavLink } from 'react-router-dom';

import './Footer.styles.scss';

import LogoImage from '~/assets/images/legalverse-logo.png';
import TextImage from '~/assets/images/legalverse-text1.png';
import useToggle from '~/hooks/useToggle';
import ContactDialog from '~/components/dialogs/ContactDialog';

const navs = [
  {
    to: '/',
    exact: true,
    label: 'Free PDF Redactor',
  },
  {
    to: 'https://legalverse.io/',
    target: '_blank',
    label: 'Legalverse',
    external: true,
  },
  {
    to: '/terms-of-service',
    label: 'Terms of Service',
  },
  {
    to: '/privacy-policy',
    label: 'Privacy Policy',
  },
];

const Footer = () => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className="footer">
      <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <div className="flex justify-center items-center mb-5">
          <a
            href="https://legalverse.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center">
              <img src={LogoImage} alt="logo" className="mr-4 w-auto h-10" />
              <img src={TextImage} alt="legalverse" className="h-5" />
            </div>
          </a>
        </div>
        <div className="text-sm text-center max-w-lg mx-auto mb-5 text-white text-opacity-75">
          Your fast and free solution to redact text and data from documents
          including PDFs. The best alternative to using Adobe products.
        </div>
        <nav className="-mx-5 -my-2 flex flex-col items-center md:flex-row flex-wrap justify-center mb-5">
          {navs.map(({ label, external, to, ...props }) => (
            <div key={to} className="px-10 py-2">
              {external ? (
                <a
                  href={to}
                  {...props}
                  className="text-sm leading-6 text-white hover:text-primary transition-colors ease-in-out"
                >
                  {label}
                </a>
              ) : (
                <NavLink
                  to={to}
                  className="text-sm leading-6 text-white hover:text-primary transition-colors ease-in-out"
                  activeClassName="text-primary"
                  {...props}
                >
                  {label}
                </NavLink>
              )}
            </div>
          ))}
          <div className="px-10 py-2">
            <span
              className="text-sm leading-6 text-white hover:text-primary transition-colors ease-in-out underline cursor-pointer"
              onClick={() => toggleOpen(true)}
            >
              Contact us
            </span>
          </div>
        </nav>
        <hr className="divider mb-5" />
        <div className="copyright">
          <p className="text-center text-sm leading-6">
            &copy; Copyright 2020. All rights reserved by Legalverse.
          </p>
        </div>
      </div>
      <ContactDialog isOpen={isOpen} onClose={() => toggleOpen(false)} />
    </div>
  );
};

export default Footer;
