import React, { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { Toasts } from '@levinologylabs/pdf_editor';
import * as yup from 'yup';

import Dialog from '../shared/Dialog';
import Input from '../shared/Input';
import Button from '../shared/Button';
import freePdf from '~/services/FreePdf';

const schema = yup.object().shape({
  subject: yup.string().required('Subject is required.'),
  name: yup
    .string()
    .required('Name is required.')
    .max(100, 'Name must be less than 100 characters.'),
  email: yup
    .string()
    .required('Email is required.')
    .email('Email not valid.')
    .max(200, 'Email must be less than 200 characters.'),
  description: yup.string().required('Message is required.'),
});

const ContactDialog = ({ subject, email, description, isOpen, onClose }) => {
  const { control, handleSubmit, errors, setValue, formState } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (isOpen) {
      setValue('subject', subject);
    }
  }, [isOpen]);

  console.log('formState', formState);

  const onSubmit = useCallback(async (values) => {
    try {
      await freePdf.contact(values);
      Toasts.success({
        title: 'Thank you!',
        description:
          'Your message has been successfully sent. We will contact you very soon!',
      });
      onClose();
    } catch (error) {
      Toasts.error({ description: error });
    }
  }, []);

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <div className="max-w-lg mx-auto lg:max-w-none">
        <h2 className="text-2xl text-center mb-10">Contact form</h2>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid grid-cols-1 gap-y-2"
        >
          <Controller
            as={<Input />}
            control={control}
            name="subject"
            label="Subject"
            error={errors.subject?.message}
            placeholder="Subject"
            defaultValue={subject}
            readOnly={!!subject}
          />
          <Controller
            as={<Input />}
            control={control}
            name="name"
            label="Full name"
            error={errors.name?.message}
            placeholder="Name"
          />
          <Controller
            as={<Input />}
            control={control}
            name="email"
            type="email"
            label="Email"
            error={errors.email?.message}
            placeholder="Email"
            defaultValue={email}
          />
          <Controller
            as={<Input />}
            control={control}
            name="description"
            label="Message"
            error={errors.description?.message}
            placeholder="Message"
            textarea
            defaultValue={description}
          />

          <div className="mt-4 flex justify-between">
            <Button onClick={onClose}>Cancel</Button>
            <Button disabled={formState.isSubmitting} type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ContactDialog;
