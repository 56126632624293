import { useState, useCallback } from 'react';

const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const toggle = useCallback((val) => {
    if (typeof val === 'boolean' && val !== undefined) {
      setValue(val);
    } else {
      setValue((v) => !v);
    }
  }, []);

  return [value, toggle];
};

export default useToggle;
