import jwtDecode from 'jwt-decode';
import { STORAGE } from '~/constants';

export const hasValidToken = () => {
  const token = localStorage.getItem(STORAGE.authToken);
  if (!token) return false;

  const { exp } = jwtDecode(token);

  return exp >= Date.now() / 1000;
};

export const handleError = (error) =>
  error?.data?.Error ||
  error?.message ||
  (typeof error === 'string' && error) ||
  'Something went wrong';
