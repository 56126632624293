import React, { useEffect, useMemo, Suspense } from 'react';
import { useLocation } from 'react-router';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useEditor, Loader } from '@levinologylabs/pdf_editor';

import router from '~/router';

const AppRoute = (props) => {
  const {
    component: Component,
    layout: Layout,
    meta = {},
    redirect,
    ...rest
  } = props;

  const state = useEditor();

  const toRedirect = useMemo(
    () => router.beforeEnter(props, state) || redirect,
    []
  );

  return toRedirect ? (
    <Redirect to={toRedirect} />
  ) : (
    <Route
      {...rest}
      render={() => (
        <Layout>
          <Component {...props} {...meta} />
        </Layout>
      )}
    />
  );
};

const AppRouter = () => {
  const location = useLocation();

  useEffect(() => {
    if (router.scrollBehavior) {
      window.scrollTo({ top: 0 });
    }
  }, [location.pathname]);

  const routes = useMemo(
    () =>
      router.routes.map((routeProps, index) => (
        <AppRoute key={index} {...routeProps} />
      )),
    []
  );

  return (
    <Suspense
      fallback={
        <div className="h-screen w-screen flex items-center justify-center">
          <Loader />
        </div>
      }
    >
      <Switch>{routes}</Switch>
    </Suspense>
  );
};
export default AppRouter;
