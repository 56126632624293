import React from 'react';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import { EditorProvider } from '@levinologylabs/pdf_editor';

import CustomErrorBoundary from '~/containers/ErrorBoundary';
import RouterSwitch from '~/containers/RouterSwitch';

const appsignal = new Appsignal({
  key: process.env.REACT_APP_APPSIGNAL_KEY,
});

const FallbackComponent = () => <div>Uh oh! There was an error :(</div>;

const App = () => {
  const providers = (
    <EditorProvider>
      <RouterSwitch />
    </EditorProvider>
  );

  if (process.env.NODE_ENV === 'production') {
    return (
      <ErrorBoundary
        instance={appsignal}
        tags={{ tag: 'value' }}
        fallback={() => <FallbackComponent />}
      >
        {providers}
      </ErrorBoundary>
    );
  }

  return <CustomErrorBoundary>{providers}</CustomErrorBoundary>;
};

export default App;
