import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@tailwindui/react';
import useOutsideClick from '~/hooks/useOutsideClick';

const Dialog = ({ isOpen, children, onClose }) => {
  const ref = useRef();
  useOutsideClick(ref, () => onClose());

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  return createPortal(
    <Transition
      show={isOpen}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed z-10 inset-0 overflow-y-auto h-screen">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
          <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
          </Transition>
          <Transition
            show={isOpen}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            className="w-full flex justify-center items-center h-full absolute"
          >
            <div
              ref={ref}
              className="w-full -mt-10 bg-white text-left rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all my-8 sm:mt-16 max-w-sm sm:align-middle sm:max-w-2xl sm:w-full sm:p-6"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              {children}
            </div>
          </Transition>
        </div>
      </div>
    </Transition>,
    document.querySelector('#root')
  );
};

export default Dialog;
