import React from 'react';
import { Helmet } from 'react-helmet';

const Scripts = () =>
  process.env.NODE_ENV === 'production' && (
    <Helmet>
      {/* Intercom */}
      {/* <script>
        {`
          window.intercomSettings = {
            app_id: "oqywplzv",
            horizontal_padding: 60,
            vertical_padding: 20	
          };
        `}
      </script>
      <script>
        {`(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/oqywplzv';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload', l);}else{w.addEventListener('load', l, false);}}})();`}
      </script> */}
      {/* Google-analytics */}
      {process.env.REACT_APP_GA_KEY && (
        <script>
          {`(function(i, s, o, g, r, a, m) {i['GoogleAnalyticsObject'] = r;i[r] = i[r] || function() {(i[r].q = i[r].q || []).push(arguments)}, i[r].l = 1 * new Date();a = s.createElement(o), m = s.getElementsByTagName(o)[0];a.async = 1;a.src = g;m.parentNode.insertBefore(a, m)})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');ga('create', '${process.env.REACT_APP_GA_KEY}', 'auto');ga('send', 'pageview');`}
        </script>
      )}
    </Helmet>
  );

export default Scripts;
